/*@import "~antd/dist/antd.css";*/

body{
  direction: rtl;
  margin: 0px;
}
.Toastify__toast--default {
  background: rgb(26,145,218);
  color: rgb(255,255,255)
}
